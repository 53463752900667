import React, { Component, Fragment } from "react";
import Scrollspy from 'react-scrollspy';
import ScrollToTop from 'react-scroll-up';
import { FiChevronUp, FiX, FiMenu } from "react-icons/fi";
import ServiceList from "../elements/service/ServiceList";
import CounterOne from "../elements/counters/CounterOne";
import FooterTwo from "../component/footer/FooterTwo";
import Contact from "../elements/contact/ContactTwo";
import Helmet from "../component/common/Helmet";


const SlideList = [
    {
        textPosition: 'text-center',
        category: '',
        title: 'Carrozzeria Lovera',
        subtitle: '',
        description: 'La carrozzeria di fiducia per tutti i tipi di vetture. Alti standard di controlli e di revisioni per circolare in tutta sicurezza.',
        buttonText: 'Contattaci',
        buttonLink: '/#contattaci'
    }
]


class CreativeLanding extends Component {
    constructor(props) {
        super(props);
        this.menuTrigger = this.menuTrigger.bind(this);
        this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
        this.stickyHeader = this.stickyHeader.bind(this);

        //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
        window.addEventListener('load', function () {
            console.log('All assets are loaded');
        })
    }
    menuTrigger() {
        document.querySelector('.header-wrapper').classList.toggle('menu-open')
    }
    CLoseMenuTrigger() {
        document.querySelector('.header-wrapper').classList.remove('menu-open')
    }
    stickyHeader() { }
    render() {

        window.addEventListener('scroll', function () {
            var value = window.scrollY;
            if (value > 100) {
                document.querySelector('.header--fixed').classList.add('sticky')
            } else {
                document.querySelector('.header--fixed').classList.remove('sticky')
            }
        });

        var elements = document.querySelectorAll('.has-droupdown > a');
        for (var i in elements) {
            if (elements.hasOwnProperty(i)) {
                elements[i].onclick = function () {
                    this.parentElement.querySelector('.submenu').classList.toggle("active");
                    this.classList.toggle("open");
                }
            }
        }

        return (
            <Fragment>
                <Helmet pageTitle="Carrozzeria Lovera" />
                {/* Start Header Area  */}
                <header className="header-area formobile-menu header--fixed default-color">
                    <div className="header-wrapper" id="header-wrapper">
                        <div className="header-left">
                            <div className="logo">
                                <a href="/">
                                    <img className="logo-1" src="/assets/images/logo/logo-light.png" alt="Logo Images" />
                                    <img className="logo-2" src="/assets/images/logo/logo-all-dark.png" alt="Logo Images" />
                                </a>
                            </div>
                        </div>
                        <div className="header-right">
                            <nav className="mainmenunav d-lg-block">
                                <Scrollspy className="mainmenu" items={['home', 'carrozzeria', 'dettagli', 'chi-siamo', 'contattaci']} currentClassName="is-current" offset={-200}>
                                    <li><a href="#home">Home</a></li>
                                    <li><a href="#carrozzeria">Carrozzeria</a></li>
                                    <li><a href="#dettagli">Dettagli</a></li>
                                    <li><a href="#chi-siamo">Chi siamo</a></li>
                                </Scrollspy>
                            </nav>
                            <div className="header-btn">
                                <a className="rn-btn" href="#contattaci">
                                    <span>Contattaci</span>
                                </a>
                            </div>
                            {/* Start Humberger Menu  */}
                            <div className="humberger-menu d-block d-lg-none pl--20">
                                <span onClick={this.menuTrigger} className="menutrigger text-white"><FiMenu /></span>
                            </div>
                            {/* End Humberger Menu  */}
                            <div className="close-menu d-block d-lg-none">
                                <span onClick={this.CLoseMenuTrigger} className="closeTrigger"><FiX /></span>
                            </div>
                        </div>
                    </div>
                </header>
                {/* End Header Area  */}

                {/* Start Slider Area   */}
                <div className="slider-activation slider-creative-agency" id="home">
                    <div className="bg_image bg_image--2" data-black-overlay="5">
                        {SlideList.map((value, index) => (
                            <div className="slide slide-style-2 slider-paralax d-flex align-items-center justify-content-center" key={index}>
                                <div className="container">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className={`inner ${value.textPosition}`}>
                                                {value.category ? <span>{value.category}</span> : ''}
                                                {value.title ? <h1 className="title theme-gradient">{value.title}</h1> : ''}
                                                <br />
                                                {value.subtitle ? <h2 className="title theme-gradient">{value.subtitle}</h2> : ''}
                                                {value.description ? <p className="description">{value.description}</p> : ''}
                                                {value.buttonText ? <div className="slide-btn"><a className="rn-button-style--2 btn-primary-color" href={`${value.buttonLink}`}>{value.buttonText}</a></div> : ''}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                {/* End Slider Area   */}

                {/* Start Intro Area */}
                <div className="about-area ptb--120 bg_color--5" id="carrozzeria">
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                                <div className="col-lg-7">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                            <h2 className="title">Carrozzeria.</h2>
                                            <p className="description">La <b>Carrozzeria Lovera</b>, sita a <b>Verzuolo</b>, offre una <b>vasta gamma di servizi</b> pronti a soddisfare ed andare incontro alle esigenze di ogni singolo cliente. I nostri professionisti, specializzati in particolar modo nella <b>riparazione e verniciatura della carrozzeria</b> di auto e moto, saranno in grado di occuparsi di interventi che vanno dalla risoluzione di <b>piccole imperfezioni</b> alla riparazione di <b>danni</b> più importanti <b>causati da sinistri</b>. Che tu possieda un’autovettura d’epoca, un veicolo appartenente ai brand più prestigiosi, o una comune utilitaria, la scelta migliore è quella di affidarti ai nostri esperti, che si prenderanno cura in maniera scrupolosa del tuo mezzo. Il nostro staff sarà in grado di far apparire come nuovo il tuo vecchio veicolo, e grazie agli <b>alti standard di controlli e di revisione</b>, ti permetterà di circolare in tutta sicurezza.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-5">
                                    <div className="thumbnail">
                                        <img className="w-100" src="/assets/images/contact/contact-3.jpg" alt="CWR3" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Intro Area */}

                {/* Start Service Area  */}
                <div className="service-area creative-service-wrapper ptb--120 bg_color--1" id="dettagli">
                    <div className="container">
                        <div className="row creative-service">
                            <div className="col-lg-12">
                                <ServiceList item="6" column="col-lg-4 col-md-6 col-sm-6 col-12 text-left" />
                            </div>
                        </div>
                    </div>
                </div>
                {/* End Service Area  */}

                {/* Start About Area */}
                <div className="about-area ptb--120 bg_color--5" id="chi-siamo">
                    <div className="about-wrapper">
                        <div className="container">
                            <div className="row row--35 align-items-center">
                                <div className="col-lg-5">
                                    <div className="thumbnail">
                                        <img className="w-100" src="/assets/images/about/about.jpg" alt="Chi siamo" />
                                    </div>
                                </div>
                                <div className="col-lg-7">
                                    <div className="about-inner inner">
                                        <div className="section-title">
                                            <h2 className="title">Chi siamo.</h2>
                                            <p className="description">Tra i molteplici servizi offerti dalla Carrozzeria Lovera, è possibile usufruire della nostra <b>assistenza in caso di sinistri</b>. Se hai subito un incidente, puoi difatti rivolgerti al nostro staff di professionisti che provvederà al <b>soccorso stradale</b>, si occuperà di seguirti scrupolosamente nel <b>disbrigo delle pratiche assicurative</b>, ti fornirà una <b>perizia approfondita</b> ed una <b>corretta stima del danno</b>. Il nostro obiettivo è quello di sgravarti da ogni preoccupazione, prendendo in carico tutto l’iter burocratico, provvedendo alla riparazione del veicolo e fornendoti, qualora sia necessario, una vettura di cortesia.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* End About Area */}

                {/* Start Contact Us */}
                <div className="rn-contact-us ptb--120 bg_color--5" id="contattaci">
                    <Contact />
                </div>
                {/* End Contact Us */}

                {/* Start Footer Style  */}
                <FooterTwo />
                {/* End Footer Style  */}
                {/* Start Back To Top */}
                <div className="backto-top">
                    <ScrollToTop showUnder={160}>
                        <FiChevronUp />
                    </ScrollToTop>
                </div>
                {/* End Back To Top */}

            </Fragment>
        )
    }
}

export default CreativeLanding;