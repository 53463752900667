import React, { Component } from "react";

class ContactTwo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rnName: '',
            rnEmail: '',
            rnPhone: '',
            rnMessage: ''
        };
    }

    sendMail(e) {
        if (this.state.rnEmail && this.state.rnName) {
            e.preventDefault();
            fetch("https://bagubits.it/mail.php?" +
                "email=" + (this.state.rnEmail ? this.state.rnEmail : '') +
                "&name=" + (this.state.rnName ? this.state.rnName : '') +
                "&phone=" + (this.state.rnPhone ? this.state.rnPhone : '') +
                "&message=" + (this.state.rnMessage ? this.state.rnMessage : '') +
                "&to=andre.bernard@bagubits.it"
            ).then(res => {
                console.log(res);
                alert("Grazie! Il tuo messaggio è stato inviato correttamente.");
                this.state = {
                    rnName: '',
                    rnEmail: '',
                    rnPhone: '',
                    rnMessage: ''
                };
            }).catch(err => {
                console.log(err);
                alert("Grazie! Il tuo messaggio è stato inviato correttamente.");
            });
        }
    }

    render() {
        return (
            <div className="contact-form--1">
                <div className="container">
                    <div className="row row--35 align-items-start">
                        <div className="col-lg-6 order-2 order-lg-1">
                            <div className="section-title text-left mb--50">
                                <h2 className="title">Contattaci.</h2>
                                <p className="description">Per maggiori informazioni o per richiedere un preventivo.</p>
                            </div>
                            <div className="form-wrapper">
                                <form onSubmit={(e) => this.sendMail(e)}>
                                    <h4 className="title">I tuoi dati</h4>
                                    <label htmlFor="item01">
                                        <input
                                            type="text"
                                            name="name"
                                            id="item01"
                                            value={this.state.rnName}
                                            onChange={(e) => { this.setState({ rnName: e.target.value }); }}
                                            placeholder="Nome *"
                                        />
                                    </label>
                                    <label htmlFor="item02">
                                        <input
                                            type="text"
                                            name="phone"
                                            id="item02"
                                            value={this.state.rnPhone}
                                            onChange={(e) => { this.setState({ rnPhone: e.target.value }); }}
                                            placeholder="Telefono"
                                        />
                                    </label>
                                    <label htmlFor="item03">
                                        <input
                                            type="text"
                                            name="email"
                                            id="item03"
                                            value={this.state.rnEmail}
                                            onChange={(e) => { this.setState({ rnEmail: e.target.value }); }}
                                            placeholder="Email *"
                                        />
                                    </label>
                                    <label htmlFor="item04">
                                        <textarea
                                            name="message"
                                            id="item04"
                                            value={this.state.rnMessage}
                                            onChange={(e) => { this.setState({ rnMessage: e.target.value }); }}
                                            placeholder="Messaggio *"
                                        />
                                    </label>
                                    <button className="rn-button-style--2 btn-solid" type="submit" value="submit" name="submit" id="mc-embedded-subscribe">Invia</button>
                                </form>
                            </div>
                        </div>
                        <div className="col-lg-6 order-1 order-lg-2">
                            <div className="thumbnail mb_md--30 mb_sm--30">
                                <img src="/assets/images/contact/contact-1.jpg" alt="trydo" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
export default ContactTwo;